<script setup lang="ts">
import { validateNotEmpty, validateEmail, validateFileSize } from '@mop/shared/utils/validationRules';
import { scrollToPosition } from '@mop/shared/utils/util';
import type { UiNotificationStatuses } from '@mop/ui2';
import type { Alternate, SeoBreadcrumb } from '@/types/cms';
import { isNumber } from '@/utils/util';

defineOptions({
  name: 'JobApply',
});

const { $gtm, $mopI18n, $urls, $storyblokLivePreview } = useNuxtApp();
const classNameRef = ref(['']);
const loadingRef = ref(false);
const route = useRoute();
const router = useRouter();
const { getCmsStory, trackPageView, cmsStoryModelRef, loadingRef: loadingCms } = useMopCms();
const { initTransition } = useMopPageTransitionClient();
const { fetchJob, jobItemRef, sendApplication, getJobIdFromPath, statusModelRef } = useMopJob();
const { getHeadObject, getAlternatesFromStory, setAlternates, getBreadcrumbSchema } = useMopSeo();
// @ts-ignore
const jobId = getJobIdFromPath(route.params.jobId);
initTransition(classNameRef, loadingCms);

const notificationMessageRef = ref('');
const notificationTypeRef = ref<UiNotificationStatuses>('success');
const formEl = ref();
const formStateRef = ref({
  formData: {
    firstName: '',
    lastName: '',
    preferredName: '',
    email: '',
    phone: '',
    files: undefined,
    additionalFiles: undefined,
  },
});

const rules = {
  validatePhone: (value: any) => /[<>]/g.test(value) === false || $mopI18n.t('locale.validate.phone'),
  validateAlphabets: (value: any) => /^[\p{L} \-']+$/giu.test(value.trim()) || $mopI18n.t('locale.validate.not_valid'),
  validateNotEmpty: (value: any) => validateNotEmpty(value) || $mopI18n.t('locale.validate.required'),
  validateEmail: (value: string) => validateEmail(value) || $mopI18n.t('locale.validate.email'),
  validateFileCount: (value: FileList) => {
    if (!value?.length) {
      return $mopI18n.t('locale.validate.required');
    }
    return value.length === 1 || $mopI18n.t('locale.validate.max_file_count');
  },
  validateAdditionalFileCount: (value: FileList) => {
    return !value || value.length <= 10 || $mopI18n.t('locale.validate.max_file_count');
  },
  validateFileSize: (value: FileList) => {
    if (!value) {
      return true;
    }
    if (Array.from(value).some((file: File) => validateFileSize(1024, file) === false)) {
      return $mopI18n.t('locale.validate.max_file_size');
    }
    return true;
  },
  validateFileType: (value: FileList) => {
    if (!value) {
      return true;
    }
    if (Array.from(value).some((file: File) => file.name.endsWith('pdf') === false)) {
      return $mopI18n.t('locale.validate.file_type');
    }
    return true;
  },
};

async function handleSubmit() {
  loadingRef.value = true;
  formStateRef.value.formData.email = formStateRef.value.formData.email.trim();

  const payload = new FormData(formEl.value.$el);
  payload.append('vacancyId', jobId);
  payload.delete('files-temp[]');
  for (const file of formStateRef.value.formData.files! as FileList) {
    payload.append('files[]', file);
  }
  payload.delete('additional-files-temp[]');
  if (formStateRef.value.formData?.additionalFiles) {
    for (const file of formStateRef.value.formData.additionalFiles as FileList) {
      payload.append('additionalFiles[]', file);
    }
  }

  await sendApplication(payload);
  const result: any = statusModelRef.value.getResult();
  if (!isNumber(result) || statusModelRef.value.hasError()) {
    const tranlationKey = `locale.externalJobErrors.${result}`;
    notificationMessageRef.value =
      result && $mopI18n.te(tranlationKey) ? $mopI18n.t(tranlationKey) : result || $mopI18n.t('locale.jobs.post_error');
    notificationTypeRef.value = 'error';
    scrollToPosition(0);
  } else {
    $gtm.trackEvent({
      eventName: 'purchase',
      customValue: {
        affiliation: 'manual',
        transaction_id: String(result),
        items: [jobItemRef.value.getGtmTrackData()],
      },
    });
    await router.push($mopI18n.localePath($urls.CAREER_THANK_YOU_PAGE));
  }
  loadingRef.value = false;
}

const metaData = computed(() => {
  const careerLink: string = $mopI18n.localePath($urls.CAREER);
  const startCreatingLink: string = $mopI18n.localePath($urls.CAREER_START_CREATING);
  const ourJobsLink: string = $mopI18n.localePath($urls.CAREER_PAGE);
  const title: string = jobItemRef.value.getTitle();
  const description: string = cmsStoryModelRef?.value.getSeo()?.description?.replace('{job-title}', title) || title;
  const isIndexable = true;
  const canonical = useRoute().path;
  let alternates: Alternate[] = getAlternatesFromStory(cmsStoryModelRef.value);
  alternates = alternates.map((alternate: Alternate) => {
    const hrefSplit: string[] = alternate.href.split('/');
    const href = `${hrefSplit.join('/')}/${jobItemRef.value.getUrl()}`;
    return {
      href,
      lang: alternate.lang,
    };
  });
  setAlternates(alternates);

  const breadcrumbs: SeoBreadcrumb[] = [
    {
      name: $mopI18n.t('locale.blog.name_career'),
      url: careerLink,
    },
    {
      name: $mopI18n.t('locale.jobs.start_creating_with_us'),
      url: startCreatingLink,
    },
    {
      name: $mopI18n.t('locale.jobs.search_button'),
      url: ourJobsLink,
    },
    {
      name: title,
      url: route.path,
    },
  ];
  const ogDescription: string = jobItemRef.value.getDescription1();
  const ogImage: string = cmsStoryModelRef?.value.getAttribute('ogImage');
  const headObject = getHeadObject({
    title,
    description,
    isIndexable,
    canonical,
    alternates,
    ogDescription,
    ogImage,
  });

  return { ...headObject, ...getBreadcrumbSchema(breadcrumbs) };
});

onMounted(async () => {
  await Promise.all([getCmsStory('/career/start-creating-with-us/our-jobs/apply'), fetchJob(jobId)]);
  if (!jobItemRef.value.getId() && !$storyblokLivePreview.isEnabledInIframe) {
    throw createError({
      statusCode: 404,
      message: 'NOT_FOUND',
    });
  } else {
    trackPageView('Career');
    $gtm.trackEvent({
      eventName: 'begin_checkout',
      customValue: {
        items: [jobItemRef.value.getGtmTrackData()],
      },
    });
  }
});

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef);

useHead(metaData);
</script>

<template>
  <div :class="['job-apply', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />

    <div class="transition-content">
      <Ui2Notification v-if="notificationMessageRef" :status="notificationTypeRef" type="primary" show-icon>{{
        notificationMessageRef
      }}</Ui2Notification>

      <MopCmsContentElementsSlot
        id="job-apply__content-top"
        class="job-apply__content-top"
        no-padding
        :elements="cmsStoryModelRef.getContentElements('bodyTop')"
      />

      <h2 class="job-apply__subline">
        <NuxtLink
          class="job-apply__link-to-job"
          :to="$mopI18n.localePath(`${$urls.CAREER_PAGE}/${jobItemRef.getUrl()}`)"
          no-prefetch
        >
          {{ jobItemRef.getTitle() }}
        </NuxtLink>
      </h2>

      <Ui2Form ref="formEl" class="job-apply__form" long @submit="handleSubmit">
        <div class="job-apply__subline">
          {{ $mopI18n.t('locale.jobs.personal_details') }}
        </div>

        <Ui2TextFieldInput
          id="first-name"
          v-model="formStateRef.formData.firstName"
          name="first-name"
          :label="$mopI18n.t('locale.contact.label.first_name')"
          floating
          :rules="[rules.validateNotEmpty, rules.validateAlphabets]"
          required
          autocomplete="given-name"
        />

        <Ui2TextFieldInput
          id="last-name"
          v-model="formStateRef.formData.lastName"
          name="last-name"
          :label="$mopI18n.t('locale.contact.label.last_name')"
          floating
          :rules="[rules.validateNotEmpty, rules.validateAlphabets]"
          required
          autocomplete="family-name"
        />

        <Ui2TextFieldInput
          id="preferred-name"
          v-model="formStateRef.formData.preferredName"
          name="preferred-name"
          :label="$mopI18n.t('locale.contact.label.preferred_name')"
          floating
          :rules="[rules.validateNotEmpty, rules.validateAlphabets]"
          required
          autocomplete="preferred-name"
        />

        <Ui2TextFieldInput
          id="email"
          v-model="formStateRef.formData.email"
          name="email"
          type="email"
          :label="$mopI18n.t('locale.contact.label.email')"
          floating
          :rules="[rules.validateNotEmpty, rules.validateEmail]"
          required
          autocomplete="email"
        />

        <Ui2TextFieldInput
          id="phone"
          v-model="formStateRef.formData.phone"
          name="phone"
          type="phone"
          :label="$mopI18n.t('locale.contact.label.phone')"
          floating
          :rules="[rules.validateNotEmpty, rules.validatePhone]"
          required
          autocomplete="tel"
        />
        <div class="job-apply__form-group">
          <div class="job-apply__form-label">{{ $mopI18n.t('locale.jobs.upload_file') }}</div>
          <Ui2FileUpload
            id="files"
            v-model="formStateRef.formData.files"
            name="files-temp[]"
            :rules="[rules.validateFileCount, rules.validateFileType, rules.validateFileSize]"
            accept=".pdf"
          />
        </div>
        <div class="job-apply__form-group">
          <div class="job-apply__form-label">{{ $mopI18n.t('locale.jobs.upload_file_additional') }}</div>
          <Ui2FileUpload
            id="filesOther"
            v-model="formStateRef.formData.additionalFiles"
            name="additional-files-temp[]"
            :rules="[rules.validateAdditionalFileCount, rules.validateFileType, rules.validateFileSize]"
            accept=".pdf"
            multiple
          />
        </div>
        <div class="job-apply__form-group">
          <Ui2Button :label="$mopI18n.t('locale.jobs.submit')" :loading="loadingRef" html-type="submit" size="lg" />
        </div>
        <div class="job-apply__form-group">
          <Ui2Button
            :label="$mopI18n.t('locale.jobs.go_back')"
            type="ghost"
            icon-start="arrow-left"
            html-type="link"
            :to="$mopI18n.localePath(`${$urls.CAREER_PAGE}/${jobItemRef.getUrl()}`)"
            size="lg"
            full-width
          />
        </div>
      </Ui2Form>

      <MopCmsContentElementsSlot
        id="job-apply__content-bottom"
        class="job-apply__content-bottom"
        no-padding
        :elements="cmsStoryModelRef.getContentElements('bodyBottom')"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.job-apply {
  padding: 0 $global-padding;
  display: flex;
  justify-content: center;
}

.job-apply__form {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: $space-12;
  margin-top: $space-24;
}

.job-apply__subline {
  @include v2-text-style(sm, highlight);
  padding: 0;
  margin: 0;
}

.job-apply__form-group {
  display: flex;
  flex-direction: column;
  gap: $space-12;
  margin-top: $space-12;
}

.job-apply__form-label {
  @include v2-text-style(sm, highlight);
}

.job-apply__link-to-job {
  @include v2-text-style(sm, highlight);
}

.job-apply__notification {
  margin: 0 $global-padding;
}

.job-apply__content-bottom {
  margin: $space30 0;
}
</style>
